﻿export default class Account {
  static init() {
    const accountPage = document.querySelector("#account-page") as HTMLElement | null;

    if (!accountPage) {
      return;
    }

    Account.initTabs();
  }

  static initTabs() {
    const tabList = document.querySelectorAll("button[data-bs-toggle=tab]") as NodeListOf<HTMLButtonElement> | null;

    if (!tabList) {
      return;
    }

    tabList.forEach((tabEl: HTMLButtonElement) => {
      tabEl.addEventListener("shown.bs.tab", (e: any) => {
        const tabTarget: string | null = e.target?.dataset.bsTarget;

        if (!tabTarget) {
          return;
        }

        const url: URL = new URL(window.location.href);
        url.searchParams.set("activeTab", tabTarget.replace("#", ""));

        history.pushState({}, "", url);
      })
    });
  }
}
