import { QuickScanProductModel, QuickScanUpdateModel } from "../models/quick-scan";
import DataLayerHelper from "../helpers/data-layer-helper";
import eventBus from "../utils/event-bus";
import Multilang from "../utils/multilang";

export default class QuickScan {
  static productList: QuickScanProductModel[] = [];
  static barcodePicker: any = null;

  static init() {
    const quickScanPage = document.querySelector("#quick-scan-page") as HTMLElement | null;

    if (!quickScanPage) {
      return;
    }

    QuickScan.initProductList();
    QuickScan.initScannerComponent();
    QuickScan.initManualComponent();

    DataLayerHelper.trackCustomEvent("quick_scan_open");
  }

  static initProductList() {
    const hiddenEl = document.querySelector("#quick-scan-product-list") as HTMLInputElement | null;

    if (!hiddenEl) {
      return;
    }

    QuickScan.productList = JSON.parse(hiddenEl.value) as QuickScanProductModel[];
  }

  static initScannerComponent() {
    const modalEl = document.querySelector("#quick-scan-scanner-modal") as HTMLElement | null;
    const modalContentEl = document.querySelector("#quick-scan-scanner-modal-content") as HTMLElement | null;
    const barcodePickerElement = document.querySelector("#quick-scan-barcode-picker") as HTMLElement | null;

    if (!modalEl || !modalContentEl || !barcodePickerElement) {
      return;
    }

    const scanditLicenseKey = barcodePickerElement.dataset.ntLicenseKey as string;
    const errorMessagePermission = Multilang.getTranslation("scanner.message.error.permission", "U dient de browser toegang te geven tot de camera om de scanfunctie te gebruiken.");
    const errorMessageOther = Multilang.getTranslation("scanner.message.error.other", "Er is een fout opgetreden.");

    try {
      // https://docs.scandit.com/stable/web/
      // https://websdk.scandit.com/

      // @ts-ignore
      if (typeof ScanditSDK === "undefined") {
        return;
      }

      let searchArea = { x: 0.2, y: 0.45, width: 0.6, height: 0.1 };

      if (/CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent)) {
        // set search area to full for Chrome on iOS
        searchArea = { x: 0.0, y: 0.0, width: 1.0, height: 1.0 };
      }

      // @ts-ignore
      const scanSettings = new ScanditSDK.ScanSettings({
        enabledSymbologies: ["ean8", "ean13", "upca", "upce", "code128", "code39", "code93", "itf"],
        codeDuplicateFilter: 1000, // minimum delay between duplicate results
        searchArea: searchArea
      });

      // @ts-ignore
      ScanditSDK.configure(scanditLicenseKey, {
        engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk/build" // TODO: trial by using ..\node_modules\scandit-sdk\build
      }).then(() => {
        // @ts-ignore
        ScanditSDK.BarcodePicker.create(barcodePickerElement, {
          playSoundOnScan: true,
          vibrateOnScan: true
        }).then((barcodePicker) => {
          // barcodePicker is ready here to be used
          QuickScan.barcodePicker = barcodePicker;

          barcodePicker.applyScanSettings(scanSettings);

          // NOTE: not needed by demand on 2024-01-18 by Cedric Nolf
          // initial pause as it will be activated by a toggle
          ////barcodePicker.pauseScanning(); // TODO: remove toggleScannerModal from QuickScanList.onMounted hook when pausing

          barcodePicker.on("scan", (scanResult) => {
            barcodePicker.pauseScanning();

            const barcode = scanResult.barcodes.reduce((string, barcode) => {
              return string + barcode.data;
            }, "");

            const updateModel = QuickScan.getUpdateModelFromProductList(barcode);
            QuickScan.showFeedback(modalContentEl, updateModel !== null);

            if (updateModel === null) {
              console.warn(`Could not find product with ${barcode}`);
              DataLayerHelper.trackCustomEvent("quick_scan_scan_failed");
              barcodePicker.resumeScanning();

              return;
            }

            eventBus.emit("quick-scan-update", updateModel);
            DataLayerHelper.trackCustomEvent("quick_scan_scan_succeeded");
            barcodePicker.resumeScanning();
          });
        }).catch((error) => {
          if (String(error).includes("Permission") || String(error).includes("NotAllowedError")) {
            toastr.error(errorMessagePermission);
          }
          else {
            toastr.error(`${errorMessageOther} (${error})`);
          }

          console.error(error);
        });
      }).catch((error) => {
        toastr.error(`${errorMessageOther} (${error})`);
        console.error(error);
      });
    } catch (error) {
      toastr.error(`${errorMessageOther} (${error})`);
      console.error(error);
    }

    eventBus.on("quick-scan-toggle-scanner", (activate: boolean) => {
      console.log("handling quick-scan-toggle-scanner", activate);

      if (QuickScan.barcodePicker === null) {
        console.warn("Could not find barcode picker instance.");
        return;
      }

      if (activate) {
        QuickScan.barcodePicker.resumeScanning();
      }
      else {
        QuickScan.barcodePicker.pauseScanning();
      }
    });
  }

  static initManualComponent() {
    // 5410439114332 -- 500 as ioq
    // 5410439120982 -- regular product
    // 5410439147231 -- 200 as ioq
    // 5902134244266 -- "no price" warning for customer "De Stalen Greep"
    // 5902134709628 -- ST as UOM without EAN, BOX as UOM with EAN

    const modalEl = document.querySelector("#quick-scan-manual-modal") as HTMLElement | null;
    const modalContentEl = document.querySelector("#quick-scan-manual-modal-content") as HTMLElement | null;
    const inputEl = document.querySelector("#quick-scan-manual-input") as HTMLInputElement | null;
    const btnEl = document.querySelector("#quick-scan-manual-add-to-list") as HTMLButtonElement | null;

    if (!modalEl || !modalContentEl || !inputEl || !btnEl) {
      return;
    }

    btnEl.addEventListener("click", () => {
      const updateModel = QuickScan.getUpdateModelFromProductList(inputEl.value);
      QuickScan.showFeedback(modalContentEl, updateModel !== null);

      if (updateModel === null) {
        console.warn(`Could not find product with ${inputEl.value}`);
        DataLayerHelper.trackCustomEvent("quick_scan_manual_failed");

        return;
      }

      eventBus.emit("quick-scan-update", updateModel);
      DataLayerHelper.trackCustomEvent("quick_scan_manual_succeeded");
      inputEl.value = "";
    });
  }

  static showFeedback(modalContentEl: HTMLElement, isSuccess: boolean) {
    if (!modalContentEl) {
      return;
    }

    // clear feedback color
    modalContentEl.classList.remove("alert-success");

    // show feedback
    if (isSuccess) {
      modalContentEl.classList.add("alert-success");
    }
    else {
      toastr.warning(Multilang.getTranslation("quick-scan.message.warning.product-not-found", "Product kon niet gevonden worden."));
    }

    // clear feedback color after certain period (notification will disappear by default)
    setTimeout(() => {
      modalContentEl.classList.remove("alert-success");
    }, 1000);
  }

  static getUpdateModelFromProductList(ean: string): QuickScanUpdateModel | null {
    const productItem = QuickScan.productList.find(p => p.ean === ean);

    if (productItem === undefined) {
      return null;
    }

    return {
      id: productItem!.id,
      pgbProductNumber: productItem!.pgbProductNumber,
      customerProductNumber: productItem!.customerProductNumber,
      unitOfMeasureValue: productItem!.unitOfMeasureValue,
      minimumOrderQuantity: productItem!.minimumOrderQuantity,
      incrementalOrderQuantity: productItem!.incrementalOrderQuantity,
      ean: productItem!.ean,
      name: productItem!.name
    };
  }
}
