import { Modal } from "bootstrap";
import $ from "jquery";

export default class MessageDialog {
    public static createInstance(identifier: string, message: string) : Modal {
        const messageDialogIdentifier = `#${identifier}`;
        const messageDialogText = $(messageDialogIdentifier).find("#nt-message-dialog-text");
        messageDialogText.html(message);
        
        const messageDialogElement = document.getElementById(identifier) as HTMLElement;
        return new Modal(messageDialogElement);
    }
    
    public static getElement(identifier: string) : HTMLElement {
        return document.getElementById(identifier) as HTMLElement;
    }
}