﻿import $ from "jquery";
import MessageDialog from "../components/bs5-message-dialog";

import "jquery-validation";

export default class BasketStep3 {
    static init() {
        this.initBlockedDelivery();
        this.initStockDataValidation();
    }

    static initBlockedDelivery() {
        // @ts-ignore
        if (typeof (blockedDeliveryData) === "undefined") {
            return;
        }
        
        // @ts-ignore
        console.log("initBlockedDelivery", blockedDeliveryData);

        // @ts-ignore
        if (blockedDeliveryData.isBlocked === false) {
            return;
        }

        $("form").on("click", "[type=submit]", (e) => {
            e.preventDefault();

            const $this = $(e.target);

            // @ts-ignore
            bootbox.dialog({
                // @ts-ignore
                message: blockedDeliveryData.message,
                buttons: {
                    cancel: {
                        // @ts-ignore
                        label: blockedDeliveryData.messageCancel
                    },
                    ok: {
                        // @ts-ignore
                        label: blockedDeliveryData.messageConfirm,
                        className: "btn-primary",
                        callback: () => {
                            const $form = $this.closest("form");

                            // @ts-ignore
                            $form.validate();
                            
                            // @ts-ignore
                            if ($form.valid() === false) {
                                return false;
                            }

                            $form.trigger("submit");
                        }
                    }
                }
            });
        });
    }

    static initStockDataValidation() {
        // @ts-ignore
        if (typeof (stockValidationData) === "undefined") {
            return;
        }
        
        // @ts-ignore
        console.log("initStockDataValidation", stockValidationData);

        // @ts-ignore
        if (stockValidationData.isBlocked) {
            return;
        }

        $("form").on("click", "[type=submit]", (e) => {
            e.preventDefault();

            // @ts-ignore
            if (NProgress) NProgress.start();

            // @ts-ignore
            const messageDialog = MessageDialog.createInstance("nt-message-dialog--check-stock-data", stockValidationData.messageCheckStockData)
            messageDialog.show();

            MessageDialog.getElement("nt-message-dialog--check-stock-data").addEventListener("shown.bs.modal", () => {
                const $form = $(e.target).closest("form");

                // @ts-ignore
                $form.validate();
                
                // @ts-ignore
                if ($form.valid() === false) {
                    // @ts-ignore
                    if (NProgress) NProgress.done();
                    messageDialog.hide();

                    return false;
                }

                $form.trigger("submit");
            })
        });
    }
}