import $ from "jquery";
import { unescape } from "lodash-es";
import DataLayerHelper from "../helpers/data-layer-helper";

export default class Software {
    static init() {
        this.initRequestModal();
        this.initRequestForm();
    }

    private static initRequestModal() {
        const modalSoftwareRequest = document.querySelector("#modal-software-request") as HTMLElement | null;

        if (!modalSoftwareRequest) {
            return;
        }

        modalSoftwareRequest.addEventListener("show.bs.modal", (e: any) => {
            const softwareName: string = e.relatedTarget.dataset.ntSoftwareName;
            const softwareText: string = e.relatedTarget.dataset.ntSoftwareText;

            DataLayerHelper.trackCustomEvent("software_request", softwareName);

            $("#form-software").find("[name='SoftwareName']").val(softwareName);
            $("#modal-software-request-text").html(unescape(softwareText));
        });
    }

    private static initRequestForm() {
        $("#form-software").on("change", ".js-software-usertype", function() {
            const $this = $(this);
            const $form = $this.closest("form");

            const $sector = $form.find(".js-software-sector");
            $sector.removeClass("d-none");

            const $sectorSelect = $sector.find("select");
            $sectorSelect.addClass("d-none");
            $sectorSelect.prop("disabled", true);

            const $target = $form.find($this.data("target"));
            $target.removeClass("d-none");
            $target.prop("disabled", false);
        });
    }
}
