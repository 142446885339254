﻿import { Dropdown } from "bootstrap";
import $ from "jquery";
import debounce from "lodash-es/debounce";
import take from "lodash-es/take";

export interface AutocompleteItem {
    value: string;
    label: string;
}

function createItems(self: Autocomplete) {
    const lookup = self.$element.val() as string;
    if (lookup.length < self.threshold()) {
        self.dropdown.hide();
        return Promise.resolve(0);
    }

    if (!!self.cachedResult && !!self.cachedResult[lookup]) {
        const items = self.cachedResult[lookup];
        const $listItems = self.$element.next();
        $listItems.html("");
        take(items, self.maximumItems()).forEach((item) => {
            $listItems.append(self.createItem(lookup, item, self.options));
        });

        // option action
        const that = self;
        self.$element
            .next()
            .find(".dropdown-item")
            .on("click", function () {
                that.$element.val($(this).text());
                if (that.options.onSelectItem) {
                    that.options.onSelectItem(
                        {
                            value: $(this).data("value"),
                            label: $(this).text(),
                        },
                        that.element
                    );
                }
            });

        return Promise.resolve(items.length);
    }

    const items: AutocompleteItem[] = [];

    const source = self.source();
    source.then((data) => {
        if (!data) {
            self.cachedResult = {};
            self.cachedResult[lookup] = [];
            return 0;
        }

        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const object = data[key];
            const item = {
                label: self.options.label ? object[self.options.label] : key,
                value: self.options.value ? object[self.options.value] : object,
            };
            if (item.label.toLowerCase().indexOf(lookup.toLowerCase()) >= 0) {
                items.push(item);
            }
        }

        const $listItems = self.$element.next();
        $listItems.html("");
        take(items, self.maximumItems()).forEach((item) => {
            $listItems.append(self.createItem(lookup, item, self.options));
        });

        // option action
        const that = self;
        self.$element
            .next()
            .find(".dropdown-item")
            .on("click", function () {
                that.$element.val($(this).text());
                if (that.options.onSelectItem) {
                    that.options.onSelectItem(
                        {
                            value: $(this).data("value"),
                            label: $(this).text(),
                        },
                        that.element
                    );
                }
            });

        self.cachedResult = {};
        self.cachedResult[lookup] = items;

        $(".js-bs-autocomplete").toggleClass("visually-hidden", items.length === 0);

        return items.length;
    });

    return source;
}

export interface AutocompleteOptions {
    dropdownOptions?: Dropdown.Options;
    dropdownClass?: string | string[];
    highlightClass?: string | string[];
    highlightTyped?: boolean;
    label?: string;
    maximumItems?: number;
    onSelectItem?: (item: AutocompleteItem, element: Element) => void;
    source: () => Promise<any>;
    treshold?: number;
    value?: string;
}

function handleClick(self: Autocomplete) {
    createItems(self)?.then((createdItems) => {
        console.log("debounce click", createdItems);
        if (createdItems == 0) {
            // prevent show empty
            self.dropdown.hide();
        } else {
            self.dropdown.show();
        }
    });
}

function handleKeyUp(self: Autocomplete) {
    createItems(self)?.then((createdItems) => {
        console.log("debounce keyup", createdItems);
        if (createdItems > 0) {
            self.dropdown.show();
        } else {
            // sets up positioning
            self.$element.trigger("click");
        }
    });
}

export default class Autocomplete {
    defaults: AutocompleteOptions = {
        treshold: 4,
        maximumItems: 5,
        highlightTyped: true,
        highlightClass: "text-primary",
        source: () => {
            return Promise.resolve();
        },
    };

    element: Element;
    $element: JQuery<Element>;
    options: AutocompleteOptions;
    dropdown: Dropdown;
    cachedResult: { [query: string]: AutocompleteItem[] };

    constructor(element: Element, options?: AutocompleteOptions) {
        this.element = element;
        this.options = !!options ? { ...this.defaults, ...options } : this.defaults;

        this.$element = $(this.element);

        this.cachedResult = {};

        // clear previously set autocomplete
        this.$element.parent().removeClass("dropdown");
        this.$element.removeAttr("data-toggle");
        this.$element.removeClass("dropdown-toggle");
        this.$element.parent().find(".dropdown-menu").remove();

        // const toDispose = new Dropdown(this.element);
        // toDispose.dispose();

        // attach dropdown
        this.$element.parent().addClass("dropdown");
        this.$element.attr("data-bs-toggle", "dropdown");
        this.$element.addClass("dropdown-toggle");
        const $dropdown = $('<ul class="dropdown-menu visually-hidden js-bs-autocomplete" style="min-width: 100%;"></ul>');

        // attach dropdown class
        if (this.options.dropdownClass) $dropdown.addClass(this.options.dropdownClass);
        this.$element.after($dropdown);

        this.dropdown = new Dropdown(this.element, this.options.dropdownOptions);
        const self = this;

        const debouncedClickHandler = debounce(handleClick, 300);
        const debouncedCKeyUpHandler = debounce(handleKeyUp, 300);

        this.$element.off("click").on("click", function (e) {
            e.stopPropagation();
            debouncedClickHandler(self);
        });

        // show options
        this.$element.off("keyup").on("keyup", function () {
            debouncedCKeyUpHandler(self);
        });
    }

    public threshold() {
        return this.options.treshold ?? 4;
    }

    public source() {
        return this.options.source() ?? {};
    }

    public maximumItems() {
        return this.options.maximumItems ?? 5;
    }

    public createItem(lookup: string, item: AutocompleteItem, opts: AutocompleteOptions): string {
        let label: string;

        if (opts.highlightTyped) {
            const idx = item.label.toLowerCase().indexOf(lookup.toLowerCase());
            label =
                item.label.substring(0, idx) +
                '<span class="' +
                this.expandClassArray(opts.highlightClass) +
                '">' +
                item.label.substring(idx, idx + lookup.length) +
                "</span>" +
                item.label.substring(idx + lookup.length, item.label.length);
        } else {
            label = item.label;
        }

        return (
            '<li><button type="button" class="dropdown-item" data-value="' + item.value + '">' + label + "</button></ul>"
        );
    }

    private expandClassArray(classes?: string | string[]): string | undefined {
        if (typeof classes === "undefined") {
            return undefined;
        }

        if (typeof classes == "string") {
            return classes;
        }

        if (classes.length == 0) {
            return "";
        }

        let ret = "";
        for (const clas of classes) {
            ret += clas + " ";
        }

        return ret.substring(0, ret.length - 1);
    }
}
