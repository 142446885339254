﻿import $ from "jquery";
import Swiper, {Navigation, Pagination, SwiperOptions} from "swiper";

export default class CustomSwiper {
    static init() {
        let bulletSwiperList = document.querySelectorAll("[data-nt-swiper='bullet']");
        for (let i = 0; i < bulletSwiperList.length; i++) {
            const el = bulletSwiperList[i] as HTMLElement;
            const $el = $(el);

            // display just before initializing the component, to prevent non-swiper elements
            $el.toggleClass("d-none", false);
            
            const options: SwiperOptions = {
                direction: "horizontal",
                watchOverflow: true,
                pagination: {
                    el: ".js-bullet-swiper-pagination",
                    clickable: true,
                },
                modules: [Pagination],
            };

            CustomSwiper.setSwiperBreakpoints($el, options);
            CustomSwiper.setSwiperSlides($el, options);

            new Swiper(el, options);
        }

        let imageSwiperList = document.querySelectorAll("[data-nt-swiper='image']");
        for (let i = 0; i < imageSwiperList.length; i++) {
            const el = imageSwiperList[i] as HTMLElement;
            const $el = $(el);

            // display just before initializing the component, to prevent non-swiper elements
            $el.toggleClass("d-none", false);

            let imageList: string[] = [];

            $el
                .find(".swiper-slide")
                .find("img")
                .each(function () {
                    imageList.push($(this).prop("src"));
                });
            
            const options: SwiperOptions = {
                direction: "horizontal",
                watchOverflow: true,
                pagination: {
                    el: ".js-image-swiper-pagination",
                    clickable: true,
                    renderBullet: (index, className) => {
                        return `<img src="${imageList[index]}" alt="" class="${className}"></img>`;
                    },
                },
                modules: [Pagination],
            };

            CustomSwiper.setSwiperBreakpoints($el, options);
            CustomSwiper.setSwiperSlides($el, options);

            new Swiper(el, options);
        }

        let navSwiperList = document.querySelectorAll("[data-nt-swiper='nav']");
        for (let i = 0; i < navSwiperList.length; i++) {

            const el = navSwiperList[i] as HTMLElement;
            const $el = $(el);

            // display just before initializing the component, to prevent non-swiper elements
            $el.toggleClass("d-none", false);
            
            const options: SwiperOptions = {
                direction: "horizontal",
                watchOverflow: true,
                navigation: {
                    prevEl: ".js-nav-swiper-button--prev",
                    nextEl: ".js-nav-swiper-button--next",
                },
                modules: [Navigation],
            };

            CustomSwiper.setSwiperBreakpoints($el, options);
            CustomSwiper.setSwiperSlides($el, options);

            new Swiper(el, options);
        }
    }

    private static setSwiperBreakpoints($el: JQuery<HTMLElement>, options: SwiperOptions): any {
        const setBreakpoints = $el.data("nt-swiper-breakpoints");

        if (!setBreakpoints) {
            return;
        }

        const columns = $el.data("nt-swiper-breakpoints-col") as number;

        if (columns === 4) {
            options.breakpoints = {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                },
            };

            return;
        }

        if (columns === 3) {
            options.breakpoints = {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                },
            };

            return;
        }

        if (columns === 2) {
            options.breakpoints = {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
            };

            return;
        }

        options.breakpoints = {
            0: {
                slidesPerView: 1,
                spaceBetween: 24,
            },
        };
    }

    private static setSwiperSlides($el: JQuery<HTMLElement>, options: SwiperOptions): any {
        const setSlides = $el.data("nt-swiper-slides");

        if (!setSlides) {
            return;
        }
        
        options.slidesPerView = $el.data("nt-swiper-slides-col") as number;
        options.spaceBetween = 24;
    }
}