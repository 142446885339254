import $ from "jquery";
import CustomHelper from "../helpers/custom-helper";
import Multilang from "../utils/multilang";
import Upload from "./upload";

interface TerminalData {
    selfHostUrl: string;
    selfHostFallbackUrl: string;
    customerId: number;
    personId: number;
    salesOrganizationId: number;
    customerType: string;
    languageCode: string;
    terminalType: string;
}

export default class Terminal {
    static init() {
        // @ts-ignore
        if (typeof(terminalData) === "undefined") // "terminalData" is set on page
        {
            return;
        }

        // @ts-ignore
        const localTerminalData: TerminalData = terminalData;

        Multilang.load();

        // reset terminal config so the COM ports can be scanned again...
        Terminal.deleteTerminalConfigFile(localTerminalData, false);

        Terminal.toggleButton(".js-application-push", false);
        Terminal.toggleButton(".js-articles-push", false);

        $(".js-application-delete").on("click", function() {
            Terminal.deleteApplicationFile(localTerminalData, false);
        });

        $(".js-application-push").on("click", function() {
            Terminal.pushApplicationFile(localTerminalData, false);
        });

        $(".js-articles-delete").on("click", function() {
            Terminal.deleteArticleFile(localTerminalData, false);
        });
        
        $(".js-articles-push").on("click", function() {
            Terminal.pushArticleFile(localTerminalData, false);
        });

        $(".js-refresh").on("click", function() {
            Terminal.checkApplicationFile(localTerminalData, false);
        });

        $(".js-scan").on("click", function() {
            Terminal.checkScanFile(localTerminalData, false);
        });

        $(".js-scan-delete").on("click", function() {
            Terminal.deleteScanFile(localTerminalData, false);
        });

        $(".js-service-log-send").on("click", function() {
            Terminal.checkServiceLogFile(localTerminalData, false);
        });

        $(".js-terminal-log-send").on("click", function() {
            Terminal.checkTerminalLogFile(localTerminalData, false);
        });
    }

    static checkApplicationFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Check application file via " + selfHostUrl);
        
        Terminal.disableAll();

        const qGet = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/applicationfile",
            data: {
                tt: localTerminalData.terminalType,
                l: localTerminalData.languageCode,
                ct: localTerminalData.customerType
            },
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken,
            cache: false
        });

        qGet.done(function(dataGet, textStatusGet, xhrGet) {
            Terminal.enableAll();

            if (xhrGet.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.application.found", "Een nieuw applicatie bestand werd gevonden."));

                Terminal.setAvailability(".js-application-push", true);
                Terminal.checkExecutionFile(true, localTerminalData, false);
            }

            if (xhrGet.status === 304) {
                Terminal.setAvailability(".js-application-push", false);
                Terminal.checkExecutionFile(false, localTerminalData, false);
            }
        });

        qGet.fail(function(xhrGet, textStatusGet, errorThrownGet) {
            console.warn("Exception while checking an application file (" + xhrGet.status + ")", xhrGet.responseText);

            Terminal.enableAll();
            Terminal.setAvailability(".js-application-push", false);

            if (xhrGet.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.checkApplicationFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrGet.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.terminal.notfound", "De scanner reageert niet. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrGet.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static checkArticleFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Check article file via " + selfHostUrl);

        Terminal.disableAll();

        const qGet = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/articlefile",
            data: { 
                l: localTerminalData.languageCode, 
                ci: localTerminalData.customerId 
            },
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken,
            cache: false
        });

        qGet.done(function(dataGet, textStatusGet, xhrGet) {
            Terminal.enableAll();

            if (xhrGet.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.article.found", "Een nieuwe artikels bestand werd gevonden."));
                Terminal.setAvailability(".js-articles-push", true);
            }

            if (xhrGet.status === 304) {
                Terminal.setAvailability(".js-articles-push", false);
            }
        });

        qGet.fail(function(xhrGet, textStatusGet, errorThrownGet) {
            console.warn("Exception while checking an article file (" + xhrGet.status + ")", xhrGet.responseText);

            Terminal.enableAll();
            Terminal.setAvailability(".js-articles-push", false);

            if (xhrGet.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.checkArticleFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrGet.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.terminal.notfound", "De scanner reageert niet. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrGet.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }
    
    static checkExecutionFile(isAvailableApplicationFile: boolean, localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Check execution file via " + selfHostUrl);

        Terminal.disableAll();

        const qGet = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/executionfile",
            data: {
                tt: localTerminalData.terminalType,
                l: localTerminalData.languageCode,
                ct: localTerminalData.customerType
            },
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken,
            cache: false
        });

        qGet.done(function(dataGet, textStatusGet, xhrGet) {
            Terminal.enableAll();

            if (xhrGet.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.execution.found", "Een nieuw uitvoer bestand werd gevonden."));

                Terminal.setAvailability(".js-application-push", true);
                Terminal.checkArticleFile(localTerminalData, false);
            }

            if (xhrGet.status === 304) {
                Terminal.setAvailability(".js-application-push", isAvailableApplicationFile);
                Terminal.checkArticleFile(localTerminalData, false);
            }
        });

        qGet.fail(function(xhrGet, textStatusGet, errorThrownGet) {
            console.warn("Exception while checking an execution file (" + xhrGet.status + ")", xhrGet.responseText);

            Terminal.enableAll();
            Terminal.setAvailability(".js-application-push", false);

            if (xhrGet.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.checkExecutionFile(isAvailableApplicationFile, localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrGet.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.terminal.notfound", "De scanner reageert niet. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrGet.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static checkScanFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Check scan file via " + selfHostUrl);

        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-scan"), true, "");

        const qGet = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/scanfile",
            data: { ct: localTerminalData.customerType, d: false },
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken,
            cache: false
        });

        qGet.done(function(dataGet, textStatusGet, xhrGet) {
            if (xhrGet.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.scan.found", "De gescande artikels werden gevonden en worden nu verstuurd naar het winkelmandje..."));

                console.log(dataGet);
                Terminal.postScanFile(localTerminalData, dataGet);
            } else {
                CustomHelper.setLoadingButton($(".js-scan"), false, Multilang.getTranslation("terminal.action.validate", "Valideer"));
                Terminal.enableAll();
            }
        });

        qGet.fail(function(xhrGet, textStatusGet, errorThrownGet) {
            console.warn("Exception while checking a scan file (" + xhrGet.status + ")", xhrGet.responseText);

            CustomHelper.setLoadingButton($(".js-scan"), false, Multilang.getTranslation("terminal.action.validate", "Valideer"));
            Terminal.enableAll();

            if (xhrGet.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.checkScanFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrGet.status === 404) {
                if (xhrGet.responseText.indexOf("Scan file does not contain the right amount of data columns") > 0) {
                    toastr["warning"](Multilang.getTranslation("terminal.message.scan.notvalid", "De gescande artikels zijn niet volledig. Controleer het scan-bestand op de scanner."));
                } else {
                    toastr["warning"](Multilang.getTranslation("terminal.message.scan.notfound", "De gescande artikels werden niet gevonden. Zorg ervoor dat de scanner is aangesloten en op staat."));
                }
            }

            if (xhrGet.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static checkServiceLogFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Check service log file via " + selfHostUrl);

        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-service-log-send"), true, "");

        const qGet = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/servicelogfile",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken,
            cache: false
        });

        qGet.done(function(dataGet, textStatusGet, xhrGet) {
            CustomHelper.setLoadingButton($(".js-service-log-send"), false, Multilang.getTranslation("terminal.action.send", "Versturen"));
            Terminal.enableAll();

            if (xhrGet.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.servicelog.found", "De service log werd gevonden en wordt nu verstuurd naar de website..."));

                console.log(dataGet);
                Terminal.postServiceLog(localTerminalData, dataGet);
            }
        });

        qGet.fail(function(xhrGet, textStatusGet, errorThrownGet) {
            console.warn("Exception while checking a service log file (" + xhrGet.status + ")", xhrGet.responseText);

            CustomHelper.setLoadingButton($(".js-service-log-send"), false, Multilang.getTranslation("terminal.action.send", "Versturen"));
            Terminal.enableAll();

            if (xhrGet.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.checkServiceLogFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrGet.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.servicelog.notfound", "The service log werd niet gevonden.Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrGet.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static checkTerminalLogFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Check terminal log file via " + selfHostUrl);

        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-terminal-log-send"), true, "");

        const qGet = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/terminallogfile",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken,
            cache: false
        });

        qGet.done(function(dataGet, textStatusGet, xhrGet) {
            CustomHelper.setLoadingButton($(".js-terminal-log-send"), false, Multilang.getTranslation("terminal.action.send", "Versturen"));
            Terminal.enableAll();

            if (xhrGet.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.terminallog.found", "De scanner log werd gevonden en wordt nu verstuurd naar de website..."));

                console.log(dataGet);
                Terminal.postTerminalLog(localTerminalData, dataGet);
            }
        });

        qGet.fail(function(xhrGet, textStatusGet, errorThrownGet) {
            console.warn("Exception while checking a terminal log file (" + xhrGet.status + ")", xhrGet.responseText);

            CustomHelper.setLoadingButton($(".js-terminal-log-send"), false, Multilang.getTranslation("terminal.action.send", "Versturen"));
            Terminal.enableAll();

            if (xhrGet.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.checkTerminalLogFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrGet.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.terminallog.notfound", "De scanner log werd niet gevonden. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrGet.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static deleteApplicationFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Delete application file via " + selfHostUrl);

        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-application-delete"), true, "");

        const qPush = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/applicationfiledelete",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken
        });

        qPush.done(function(dataPush, textStatusPush, xhrPush) {
            CustomHelper.setLoadingButton($(".js-application-delete"), false, Multilang.getTranslation("terminal.action.delete", "Verwijderen"));
            Terminal.enableAll();

            if (xhrPush.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.application.success.delete", "Het applicatie bestand werd succesvol verwijderd."));

                Terminal.setAvailability(".js-application-delete", false);
                Terminal.deleteExecutionFile(localTerminalData, false);
            }
        });

        qPush.fail(function(xhrPush, textStatusPush, errorThrownPush) {
            console.warn("Exception while deleting an application file (" + xhrPush.status + ")", xhrPush.responseText);

            CustomHelper.setLoadingButton($(".js-application-delete"), false, Multilang.getTranslation("terminal.action.delete", "Verwijderen"));
            Terminal.enableAll();

            if (xhrPush.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.deleteApplicationFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrPush.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.terminal.notfound", "De scanner reageert niet. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrPush.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static deleteArticleFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Delete article file via " + selfHostUrl);

        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-articles-delete"), true, "");

        const qPush = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/articlefiledelete",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken
        });

        qPush.done(function(dataPush, textStatusPush, xhrPush) {
            CustomHelper.setLoadingButton($(".js-articles-delete"), false, Multilang.getTranslation("terminal.action.delete", "Verwijderen"));
            Terminal.enableAll();

            if (xhrPush.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.article.success.delete", "Het artikel bestand werd succesvol verwijderd."));

                Terminal.setAvailability(".js-articles-delete", false);
            }
        });

        qPush.fail(function(xhrPush, textStatusPush, errorThrownPush) {
            console.warn("Exception while deleting an article file (" + xhrPush.status + ")", xhrPush.responseText);

            CustomHelper.setLoadingButton($(".js-articles-delete"), false, Multilang.getTranslation("terminal.action.delete", "Verwijderen"));
            Terminal.enableAll();

            if (xhrPush.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.deleteArticleFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrPush.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.terminal.notfound", "De scanner reageert niet. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrPush.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static deleteExecutionFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Delete execution file via " + selfHostUrl);
        
        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-application-delete"), true, "");

        const qPush = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/executionfiledelete",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken
        });

        qPush.done(function(dataPush, textStatusPush, xhrPush) {
            CustomHelper.setLoadingButton($(".js-application-delete"), false, Multilang.getTranslation("terminal.action.delete", "Verwijderen"));
            Terminal.enableAll();

            if (xhrPush.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.execution.success.delete", "Het uitvoer bestand werd succesvol verwijderd."));

                Terminal.setAvailability(".js-application-delete", false);
            }
        });

        qPush.fail(function(xhrPush, textStatusPush, errorThrownPush) {
            console.warn("Exception while deleting an execution file (" + xhrPush.status + ")", xhrPush.responseText);

            CustomHelper.setLoadingButton($(".js-application-delete"), false, Multilang.getTranslation("terminal.action.delete", "Verwijderen"));
            Terminal.enableAll();

            if (xhrPush.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.deleteExecutionFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrPush.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.terminal.notfound", "De scanner reageert niet. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrPush.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static deleteScanFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Delete scan file via " + selfHostUrl);

        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-scan-delete"), true, "");

        const qPush = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/scanfiledelete",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken
        });

        qPush.done(function(dataPush, textStatusPush, xhrPush) {
            CustomHelper.setLoadingButton($(".js-scan-delete"), false, Multilang.getTranslation("terminal.action.delete.scan", "Gescande artikels verwijderen"));
            Terminal.enableAll();

            if (xhrPush.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.scan.success.delete", "De gescande artikels werden succesvol verwijderd."));

                Terminal.setAvailability(".js-scan-delete", false);
            }
        });

        qPush.fail(function(xhrPush, textStatusPush, errorThrownPush) {
            console.warn("Exception while deleting a scan file (" + xhrPush.status + ")", xhrPush.responseText);

            CustomHelper.setLoadingButton($(".js-scan-delete"), false, Multilang.getTranslation("terminal.action.delete", "Verwijderen"));
            Terminal.enableAll();

            if (xhrPush.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.deleteScanFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrPush.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.scan.notfound", "De gescande artikels werden niet gevonden. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrPush.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static deleteTerminalConfigFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Delete terminal config file via " + selfHostUrl);

        const qPush = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/terminalconfigfiledelete",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken
        });

        qPush.done(function(dataPush, textStatusPush, xhrPush) {
            // do nothing
        });

        qPush.fail(function(xhrPush, textStatusPush, errorThrownPush) {
            console.warn(
                "Exception while deleting a terminal config file (" + xhrPush.status + ")",
                xhrPush.responseText
            );

            if (xhrPush.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.deleteTerminalConfigFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrPush.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static postScanFile(localTerminalData: TerminalData, data: any) {
        const postData = {
            customerId: localTerminalData.customerId,
            personId: localTerminalData.personId,
            salesOrganizationId: localTerminalData.salesOrganizationId,
            lines: data
        };

        const qPost = $.ajax({
            type: "POST",
            url: "/upload/validatescanfile",
            data: JSON.stringify(postData),
            contentType: "application/json; charset=utf-8"
        });

        qPost.done(function(dataPost, textStatusPost, xhrPost) {
            toastr["info"](Multilang.getTranslation("terminal.message.scan.success", "De gescande artikels werden succesvol gevalideerd."));

            $("#upload-data").html(dataPost.view);

            Upload.init();

            CustomHelper.setLoadingButton($(".js-scan"), false, Multilang.getTranslation("terminal.action.validate", "Valideer"));
            Terminal.enableAll();
        });

        qPost.fail(function(xhrPost, textStatusPost, errorThrownPost) {
            toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));

            CustomHelper.setLoadingButton($(".js-scan"), false, Multilang.getTranslation("terminal.action.validate", "Valideer"));
            Terminal.enableAll();
        });
    }

    static postServiceLog(localTerminalData: TerminalData, data: any) {
        const postData = {
            customerId: localTerminalData.customerId,
            personId: localTerminalData.personId,
            logs: data
        };

        const qPost = $.ajax({
            type: "POST",
            url: "/api/servicelog",
            data: JSON.stringify(postData),
            contentType: "application/json; charset=utf-8"
        });

        qPost.done(function(dataPost, textStatusPost, xhrPost) {
            toastr["info"](Multilang.getTranslation("terminal.message.servicelog.success", "De service log werd succesvol verstuurd."));
        });

        qPost.fail(function(xhrPost, textStatusPost, errorThrownPost) {
            toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
        });
    }

    static postTerminalLog(localTerminalData: TerminalData, data: any) {
        const postData = {
            customerId: localTerminalData.customerId,
            personId: localTerminalData.personId,
            logs: data
        };

        const qPost = $.ajax({
            type: "POST",
            url: "/api/terminallog",
            data: JSON.stringify(postData),
            contentType: "application/json; charset=utf-8"
        });

        qPost.done(function(dataPost, textStatusPost, xhrPost) {
            toastr["info"](Multilang.getTranslation("terminal.message.terminallog.success", "De scanner log werd succesvol verstuurd."));
        });

        qPost.fail(function(xhrPost, textStatusPost, errorThrownPost) {
            toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
        });
    }

    static pushApplicationFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Push application file via " + selfHostUrl);

        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-application-push"), true, "");

        const qPush = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/applicationfilepush",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken
        });

        qPush.done(function(dataPush, textStatusPush, xhrPush) {
            CustomHelper.setLoadingButton($(".js-application-push"), false, Multilang.getTranslation("terminal.action.update", "Aanpassen"));
            Terminal.enableAll();

            if (xhrPush.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.application.success", "Het applicatie bestand werd succesvol overgezet."));

                Terminal.setAvailability(".js-application-push", false);
                Terminal.pushExecutionFile(localTerminalData, false);
            }
        });

        qPush.fail(function(xhrPush, textStatusPush, errorThrownPush) {
            console.warn("Exception while pushing an application file (" + xhrPush.status + ")", xhrPush.responseText);

            CustomHelper.setLoadingButton($(".js-application-push"), false, Multilang.getTranslation("terminal.action.update", "Aanpassen"));
            Terminal.enableAll();

            if (xhrPush.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.pushApplicationFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrPush.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.execution.fail", "Het applicatie bestand werd niet overgezet. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrPush.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static pushArticleFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Push article file via " + selfHostUrl);

        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-articles-push"), true, "");

        const qPush = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/articlefilepush",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken
        });

        qPush.done(function(dataPush, textStatusPush, xhrPush) {
            CustomHelper.setLoadingButton($(".js-articles-push"), false, Multilang.getTranslation("terminal.action.update", "Aanpassen"));
            Terminal.enableAll();

            if (xhrPush.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.article.success", "Het artikel bestand werd succesvol overgezet."));

                Terminal.setAvailability(".js-articles-push", false);
            }
        });

        qPush.fail(function(xhrPush, textStatusPush, errorThrownPush) {
            console.warn("Exception while pushing an article file (" + xhrPush.status + ")", xhrPush.responseText);

            CustomHelper.setLoadingButton($(".js-articles-push"), false, Multilang.getTranslation("terminal.action.update", "Aanpassen"));
            Terminal.enableAll();

            if (xhrPush.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.pushArticleFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrPush.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.article.fail", "Het artikel bestand werd niet overgezet. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrPush.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static pushExecutionFile(localTerminalData: TerminalData, isFallback: boolean) {
        const selfHostUrl = isFallback ? localTerminalData.selfHostFallbackUrl : localTerminalData.selfHostUrl;
        console.log("Push execution file via " + selfHostUrl);
        
        Terminal.disableAll();
        CustomHelper.setLoadingButton($(".js-application-push"), true, "");

        const qPush = $.ajax({
            type: "GET",
            url: selfHostUrl + "/api/executionfilepush",
            dataType: "json",
            xhr: Terminal.clearRequestValidationToken
        });

        qPush.done(function(dataPush, textStatusPush, xhrPush) {
            CustomHelper.setLoadingButton($(".js-application-push"), false, Multilang.getTranslation("terminal.action.update", "Aanpassen"));
            Terminal.enableAll();

            if (xhrPush.status === 200) {
                toastr["info"](Multilang.getTranslation("terminal.message.execution.success", "Het uitvoer bestand werd succesvol overgezet."));

                Terminal.setAvailability(".js-application-push", false);
            }
        });

        qPush.fail(function(xhrPush, textStatusPush, errorThrownPush) {
            console.warn("Exception while pushing an execution file (" + xhrPush.status + ")", xhrPush.responseText);

            CustomHelper.setLoadingButton($(".js-application-push"), false, Multilang.getTranslation("terminal.action.update", "Aanpassen"));
            Terminal.enableAll();

            if (xhrPush.status === 0) {
                if (!isFallback) {
                    console.warn("The service TerminalCommunicator does not seem to be running. Retry...");
                    Terminal.pushExecutionFile(localTerminalData, true);
                    return;
                }

                console.warn("The service TerminalCommunicator does not seem to be running.");
                toastr["error"](Multilang.getTranslation("terminal.message.service.notrunning", "De service TerminalCommunicator lijkt niet actief te zijn. Start de service adhv de knop 'Download scanner software'."));
            }

            if (xhrPush.status === 404) {
                toastr["warning"](Multilang.getTranslation("terminal.message.execution.fail", "Het uitvoer bestand werd niet overgezet. Zorg ervoor dat de scanner is aangesloten en op staat."));
            }

            if (xhrPush.status === 500) {
                toastr["error"](Multilang.getTranslation("terminal.message.exception", "Er trad een fout op!"));
            }
        });
    }

    static clearRequestValidationToken(): XMLHttpRequest {
        // @ts-ignore
        const xhr = $.ajaxSettings.xhr() as XMLHttpRequest;

        const setRequestHeader = xhr.setRequestHeader;
        xhr.setRequestHeader = function(name, value) {
            if (name === "__RequestVerificationToken") return;
            setRequestHeader.call(this, name, value);
        };

        return xhr;
    }

    static setAvailability(target: string, isAvailable: boolean) {
        const $target = $(target);

        if (isAvailable) {
            $target.removeClass("btn-secondary").addClass("btn-primary");
        } else {
            $target.removeClass("btn-primary").addClass("btn-secondary");
        }
    }

    static enableAll() {
        Terminal.toggleButton(".js-scan", true);
        Terminal.toggleButton(".js-scan-delete", true);
        Terminal.toggleButton(".js-refresh", true);
        Terminal.toggleButton(".js-application-push", true);
        Terminal.toggleButton(".js-articles-push", true);
        Terminal.toggleButton(".js-application-delete", true);
        Terminal.toggleButton(".js-articles-delete", true);
        Terminal.toggleButton(".js-service-log-send", true);
        Terminal.toggleButton(".js-terminal-log-send", true);
    }

    static disableAll() {
        Terminal.toggleButton(".js-scan", false);
        Terminal.toggleButton(".js-scan-delete", false);
        Terminal.toggleButton(".js-refresh", false);
        Terminal.toggleButton(".js-application-push", false);
        Terminal.toggleButton(".js-articles-push", false);
        Terminal.toggleButton(".js-application-delete", false);
        Terminal.toggleButton(".js-articles-delete", false);
        Terminal.toggleButton(".js-service-log-send", false);
        Terminal.toggleButton(".js-terminal-log-send", false);
    }

    static toggleButton(target: string, isEnabled: boolean) {
        if (isEnabled) {
            $(target).removeAttr("disabled");
        } else {
            $(target).attr("disabled", "disabled");
        }
    }
}