import 'toastr';
import $ from "jquery";
import QuantityValidator from "../validators/quantity-validator";
import CustomHelper from "../helpers/custom-helper";
import MiniBasket from "../components/mini-basket";
import Multilang from "../utils/multilang";

export default class Upload {
    static init() {
        this.initChangeQuantity();
        this.initChangeOrderUnit();
        this.initDeleteLine();
        this.initForm();
    }

    static initChangeQuantity() {
        const $form = $("#form-product-upload-data");

        $form.off("change", ".js-upload-quantity");
        $form.on("change", ".js-upload-quantity", (e) => {
            this.changeQuantityData($(e.target).closest("tr"), false);
        });
    }

    static initChangeOrderUnit() {
        const $form = $("#form-product-upload-data");

        $form.off("change", ".js-upload-order-unit");
        $form.on("change", ".js-upload-order-unit", (e) => {
            this.changeQuantityData($(e.target).closest("tr"), true);
        });
    }

    static initDeleteLine() {
        Multilang.load();

        const $form = $("#form-product-upload-data");

        $form.off("click", ".js-upload-delete");
        $form.on("click", ".js-upload-delete", function() {
            const $container = $(this).closest("tr");

            const confirmationMessage = Multilang.getTranslation("upload.message.confirm.delete", "Bent u zeker dat u deze lijn wenst te verwijderen?");

            // @ts-ignore
            bootbox.confirm(confirmationMessage, function() {
                const $isDeleted = $container.find(".js-upload-isdeleted");

                $isDeleted.val("true");
                $container.addClass("d-none");
            });
        });
    }

    static initForm() {
        const $form = $("#form-product-upload-data");

        $form.off("submit");
        $form.on("submit", (e) => {
            e.preventDefault();
            this.postUpload($form);
        });
    }

    static changeQuantityData($container: any, isChangeOrderUnit: boolean) {
        const $quantity = $container.find(".js-upload-quantity");
        const $quantityPrevious = $container.find(".js-upload-quantity-previous");
        const $quantityTotal = $container.find(".js-upload-quantity-total");
        const $orderUnit = $container.find(".js-upload-order-unit");

        const unitsPerPack = parseFloat($orderUnit.find("option:selected").data("upp"));

        const input = {
            requestedQuantity: parseFloat($quantity.val()),
            requestedUnitsPerPack: unitsPerPack,
            previousQuantity: parseFloat($quantityPrevious.val()),
            totalQuantity: parseFloat($quantityTotal.val()),
            minimumOrderQuantity: parseFloat($quantity.data("moq")) / unitsPerPack,
            incrementOrderQuantity: parseFloat($quantity.data("ioq")) / unitsPerPack,
            restOrderQuantity: parseFloat($quantity.data("roq")),
            roundType: $quantity.data("rnd-type"),
            roundUnitsPerPack: $quantity.data("rnd-upp"),
            roundOrderUnitId: $quantity.data("rnd-id")
        };

        // set min/step for [type=number]
        $quantity.prop("min", input.minimumOrderQuantity);
        $quantity.prop("step", input.incrementOrderQuantity);

        // set correct quantity + rounding
        const validationData = QuantityValidator.validate(input, isChangeOrderUnit);

        $quantity.val(validationData.correctQuantity);
        $quantityPrevious.val(validationData.correctQuantity);
        $quantityTotal.val(validationData.correctQuantity * input.requestedUnitsPerPack);

        if (validationData.isRounded) {
            $orderUnit.val(validationData.correctOrderUnitId);
        }
    }

    static postUpload($form) {
        Multilang.load();

        CustomHelper.setLoadingButton($form.find("[type=submit]"), true, "");

        const q = $.ajax({
            type: "POST",
            url: $form.attr("action"),
            data: $form.serialize()
        });

        q.done((data) => {
            if (data.isSuccess) {
                toastr["success"](data.message);
            }

            if (data.isWarning) {
                toastr["warning"](data.message);
            }

            $("#upload-data").html(data.view);

            MiniBasket.load();

            const buttonText = Multilang.getTranslation("upload.action.add", "Toevoegen");
            CustomHelper.setLoadingButton($form.find("[type=submit]"), false, buttonText);
        });

        q.fail((xhr, textStatus, errorThrown) => {
            console.error(xhr);
            console.error(textStatus);
            console.error(errorThrown);

            toastr["error"]("Could not upload data.");

            const buttonText = Multilang.getTranslation("upload.action.add", "Toevoegen");
            CustomHelper.setLoadingButton($form.find("[type=submit]"), false, buttonText);
        });
    }
}