import 'toastr';
import axios, { AxiosError, AxiosResponse } from "axios";
import Multilang from "../utils/multilang";
import "../extensions/string-extensions";

export default class PostalCodeValidator {
  static validate(postalCode: string, countryId: number, countryName: string) {
      Multilang.load();

      const postData = {
        postalCode: postalCode,
        countryId: countryId
      };

      axios
        .post("/api/validation/validate-postal-code", postData)
        .then((response: AxiosResponse) => response.data)
        .then(data => {
            if (!data) {
              const msg: string = Multilang.getTranslation("ValidatePostalCode.message.NOK", "De postcode {0} opgegeven voor {1} heeft geen correcte vorm.");
              toastr["error"](msg.format(postalCode, countryName));
            } else {
              const msg: string = Multilang.getTranslation("ValidatePostalCode.message.OK", "De postcode {0} opgegeven voor {1} heeft een correcte vorm.");
              toastr["success"](msg.format(postalCode, countryName));
            }
        })
        .catch((error: AxiosError) => {
            console.error(error);

            toastr["error"]("Could not validate postal code.");
        });
  }
}
