﻿import 'toastr';

export default class MiniBasket {
    static load() {
        const q = $.ajax({
            type: "GET",
            url: "/api/basket/mini"
        });

        q.done((data: number) => {
            $(".js-mini-basket-count").text(data);
        });

        q.fail((xhr, textStatus, errorThrown) => {
            // @ts-ignore
            toastr["error"]("Could not load mini basket.");
        });
    }
}