declare var dataLayer: any[];

export default class DataLayerHelper {
    static trackCustomEvent(eventName: string, eventParam: string | null = null, callback: Function | null = null) {
        eventName = eventName.toLowerCase();

        if (!eventName.startsWith("pgb_")) {
            eventName = `pgb_${eventName}`;
        }

        if (!dataLayer) {
            callback && callback();
            return;
        }

        console.debug("Pushing event to data layer", eventName)

        // noinspection JSUnusedGlobalSymbols
        dataLayer.push({
            event: eventName,
            eventParameter: eventParam,
            eventCallback: function () {
                callback && callback();
            },
            eventTimeout: 2000
        });

        return;
    }
}
