﻿import 'toastr';
import $ from "jquery";
import Datepicker from "js-datepicker";
import PostalCodeValidator from "../validators/postal-code-validator";
import { format, parse } from "date-fns";

export default class BasketStep2 {
    static init() {
        this.initDropdown();
        this.initDatePicker();
        this.initDeliveryAddressValidation();
    }

    static initDropdown() {
        $(".js-checkout-delivery-address-change").on("change", (e) => {
            const selectedValue = $(e.target)
                .find("option:selected")
                .val();
            
            if (selectedValue === null || selectedValue === "-1") {
                $(".js-checkout-delivery-address").html("");
                $("#form-delivery-address").removeClass("d-none");
            } else {
                this.getDeliveryAddress(selectedValue as number);
            }
        });
    }

    static initDatePicker() {
        const transferDateSelector = "#datepicker-transfer";

        const $transferDate = $(transferDateSelector);
        const $disabledDates = $(".js-disabled-dates");
        
        if ($transferDate.length === 0 || $disabledDates.length === 0) {
            return;
        }
        
        const disabledDates = JSON.parse($disabledDates.val() as string);

        // convert with getTime() = best way to compare dates
        const disabledTimeList: number[] = [];
        for (let i = 0; i < disabledDates.length; i++) {
            disabledTimeList.push(new Date(disabledDates[i]).getTime());
        }
        
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const todayWithinThreeMonths = new Date();
        todayWithinThreeMonths.setMonth(todayWithinThreeMonths.getMonth() + 3);
        
        // https://www.npmjs.com/package/js-datepicker
        const options = {
            startDay: 1,
            disableYearOverlay: true,
            disabler: (date: Date) => {
                return $.inArray(date.getTime(), disabledTimeList) > -1 || date <= today || date > todayWithinThreeMonths;
            },
            formatter: (input: any, date: Date, instance: any) => {
                input.value = format(date, "dd/MM/yyyy");
            },
            onSelect: (instance: any, date: Date) => {
                $("#btn-go-to-step-3").removeAttr("disabled");
            },
        };

        const datepickerTransferDate = Datepicker(transferDateSelector, options);

        const dateValue = $transferDate.val() as string;
        const parsedDateValue = parse(dateValue, "dd/MM/yyyy", new Date());
        datepickerTransferDate.setDate(parsedDateValue, true);

        const $transferDateIcon = $transferDate.closest(".input-group").find(`[type="button"]`);
        $transferDateIcon.on("click", (e: JQueryEventObject) => {
            e.stopPropagation();
            datepickerTransferDate.show();
        });
    }

    static initDeliveryAddressValidation() {
        $("#js-delivery-address__form").on(
            "change",
            "#alternate-delivery-address-postal-code, #alternate-delivery-address-country-name",
            (e) => {
                const postalCode = $("#alternate-delivery-address-postal-code").val() as string;
                
                if (postalCode !== "") {
                    const $selectedCountry = $("#alternate-delivery-address-country-name").find(":selected");
                    const countryId = $selectedCountry.val() as number;
                    const countryName = $selectedCountry.text();

                    PostalCodeValidator.validate(postalCode, countryId, countryName);
                }
            }
        );
    }

    static getDeliveryAddress(deliveryAddressId: number) {
        const q = $.ajax({
            type: "GET",
            url: "/api/shippingaddress/getdeliveryaddress",
            data: { deliveryAddressId: deliveryAddressId },
            contentType: "application/json; charset=utf-8"
        });

        q.done((data) => {
            $(".js-checkout-delivery-address").html(data);
            $("#form-delivery-address").addClass("d-none");
        });

        q.fail((xhr, textStatus, errorThrown) => {
            toastr["error"]("Could not get delivery address.");
        });
    }
}