import $ from "jquery";
import axios from "axios";
import {BootstrapBreakpoint} from "../models/enums";

export default class CustomHelper {
    static async checkOnline(): Promise<boolean> {
        try {
            const response = await axios.get("/api/online/check");
            return response.status === 200;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    /**
     * Gets the current Bootstrap v5 breakpoint.
     * The class list, per breakpoint, contains the classes for which the element will be hidden.
     */
    static getBootstrapBreakpoint(): BootstrapBreakpoint {
        const breakpointList = [
            { name: BootstrapBreakpoint.xs, classList: ["d-none", "d-sm-block"] },
            { name: BootstrapBreakpoint.sm, classList: ["d-sm-none", "d-md-block"] },
            { name: BootstrapBreakpoint.md, classList: ["d-md-none", "d-lg-block"] },
            { name: BootstrapBreakpoint.lg, classList: ["d-lg-none", "d-xl-block"] },
            { name: BootstrapBreakpoint.xl, classList: ["d-xl-none", "d-xxl-block"] },
            { name: BootstrapBreakpoint.xxl, classList: ["d-xxl-none"] }
        ];

        for (let breakpoint of breakpointList) {
            const divElement = window.document.createElement("div");
            divElement.classList.add(...breakpoint.classList);

            window.document.body.appendChild(divElement);

            if (divElement.offsetParent === null) {
                window.document.body.removeChild(divElement);

                console.log("Bootstrap Breakpoint", breakpoint.name.toString());
                return breakpoint.name;
            }

            window.document.body.removeChild(divElement);
        }

        return BootstrapBreakpoint.none;
    }

    static getDateFromString(value: any): Date {
        if (typeof value === "undefined") {
            return new Date();
        }

        const parts = value.split(/\-|T/);
        return new Date(parts[0], parts[1] - 1, parts[2]);
    }

    /**
     *  Use this method to retrieve a list of codes, where the HTML-tag has a data attribute 'list-item-code'.
     */
    static getListItemCodeList() {
        return $("[data-list-item-code]")
            .map((i: number, el: HTMLElement) => {
                return $(el).data("list-item-code");
            })
            .get();
    }

    static setLoadingButton($target: any, isLoading: boolean, labelText: string) {
        const origWidth = $target.css("width");

        if (isLoading) {
            $target.attr("disabled", "disabled");
            $target.html('<i class="fa fa-refresh fa-spin"></i>');
            $target.css("width", origWidth);
        } else {
            $target.removeAttr("disabled");
            $target.text(labelText);
        }
    }

    static setToggleSubmit($form: any, $checkbox: any) {
        if ($checkbox.is(":checked")) {
            $form.find("[type=submit]").removeAttr("disabled");
            $form.find(".js-button-submit").removeAttr("disabled");
        } else {
            $form.find("[type=submit]").attr("disabled", "disabled");
            $form.find(".js-button-submit").attr("disabled", "disabled");
        }
    }

    static smoothScrollTo(anchor: string, offset: number = 0) {
        if (anchor === "#top") {
            $("html,body").animate({ scrollTop: 0 }, 300);

            return true;
        }

        let $target = $(anchor);
        $target = ($target.length && $target) || $("[name=" + anchor.slice(1) + "]");

        if ($target.length > 0) {
            const targetOffset = $target.offset()?.top ?? 0 - (offset || 15);
            $("html,body").animate({ scrollTop: targetOffset }, 800);

            return true;
        }

        return false;
    }

    static startLoad() {
        $("body").css("cursor", "progress");
        $("<div class='nt-ajaxing-overlay'></div>")
            .hide()
            .prependTo("body")
            .delay(300)
            .fadeIn(200);
    }

    static stopLoad() {
        $("body").css("cursor", "default"); // cursor doesn't revert until mouse is moved - in chrome
        $(".nt-ajaxing-overlay").remove();
    }

    static removeUrlParameter(url: string, parameter: string) {
        // prefer to use l.search if you have a location/link object
        const urlParts = url.split("?");

        if (urlParts.length >= 2) {
            const prefix = encodeURIComponent(parameter) + "=";
            const parts = urlParts[1].split(/[&;]/g);

            // reverse iteration as may be destructive
            for (let i = parts.length; i-- > 0; ) {
                // idiom for string.startsWith
                if (parts[i].lastIndexOf(prefix, 0) !== -1) {
                    parts.splice(i, 1);
                }
            }

            url = urlParts[0] + (parts.length > 0 ? "?" + parts.join("&") : "");
        }

        return url;
    }
}
