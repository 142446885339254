import $ from "jquery";
import axios from "axios";
import 'toastr';
import Autocomplete, { AutocompleteOptions, AutocompleteItem } from "./bs5-autocomplete";

export default class SearchSuggestions {
    autocomplete: Autocomplete;
    $searchTerm: JQuery<Element>;

    constructor(element: Element) {
        this.$searchTerm = $(element);

        let options: AutocompleteOptions = {
            source: () => {
                return this.getSuggestions();
            },
            onSelectItem: (item: AutocompleteItem, element: any) => {
                this.$searchTerm.val(item.value);
                
                const $form = this.$searchTerm.closest("form");
                $form.trigger("submit");
            },
            highlightClass: "text-success",
            treshold: 0,
            maximumItems: 10,
        };

        this.autocomplete = new Autocomplete(element, options);
    }

    private async getSuggestions() {
        const input = this.$searchTerm.val() as string;

        if (input === "" || input.length < 3) {
            return Promise.resolve();
        }

        try {
            const response = await axios.get(`/api/search/suggestions?searchTerm=${input}`);
            const responseData = response.data as string[];

            let suggestionsDictionary: { [key: string]: string } = {};
            responseData.forEach((el) => (suggestionsDictionary[el] = el));

            return suggestionsDictionary;
        } catch (error) {
            toastr["error"]("Could not get search suggestions.");
            console.error(error);
        }
    }
}
