﻿import { createApp, defineAsyncComponent } from 'vue';

/*
 * Place this on a page to render one of these components:
 * <div id="vue-hello-world" data-foo="bar"></div>
 */
export default class VueComponentLoader {
    private static vueComponents = [
        { componentName: "BasketTotals", elementId: "vue-basket-totals", checkForVisibility: true },
        // { componentName: "Counter", elementId: "vue-counter", checkForVisibility: true },
        // { componentName: "Fetcher", elementId: "vue-fetcher", checkForVisibility: true },
        { componentName: "Impersonation", elementId: "vue-impersonation", checkForVisibility: true },
        { componentName: "MobileProductFilter", elementId: "vue-mobile-product-filter", checkForVisibility: true },
        { componentName: "PasswordHibpChecker", elementId: "vue-password-hibp-checker", checkForVisibility: true },
        { componentName: "PasswordStrengthIndicator", elementId: "vue-password-strength-indicator", checkForVisibility: true },
        { componentName: "QuickScanList", elementId: "vue-quick-scan-list", checkForVisibility: true }
    ];

    static load() {
        for (let vueComponent of VueComponentLoader.vueComponents) {
            const mountElement = document.getElementById(vueComponent.elementId);

            if (mountElement !== null) {
                const doMount = vueComponent.checkForVisibility ? mountElement.offsetWidth > 0 || mountElement.offsetHeight > 0 : true;

                if (doMount) {
                    // console.log("vue mounting...", mountElement);
                    const component = defineAsyncComponent(() => import(`../components/${vueComponent.componentName}.vue`));
                    createApp(component, {...mountElement.dataset}).mount("#" + vueComponent.elementId);
                }
            }
        }
    }
}
