import $ from "jquery";
import axios, { AxiosError, AxiosResponse } from "axios";
import { withQuery } from "ufo";
import ProductHelper from "../helpers/product-helper";
import MiniBasket from "../components/mini-basket";

export default class Templates {
  static init() {
    const templatesPage = document.querySelector("#templates-page") as HTMLElement | null;

    if (!templatesPage) {
      return;
    }

    Templates.initTemplates();
    Templates.initTemplateDetails();
    Templates.initOrderProduct();
  }

  static initTemplates() {
    const $row = $(".js-template-row");

    $row.off("click", ".js-template--add");
    $row.on("click", ".js-template--add", (e) => {
      const templateId = $(e.currentTarget).data("template-id");
      this.addTemplate(templateId);
    });

    $row.off("click", ".js-template--delete");
    $row.on("click", ".js-template--delete", (e) => {
      // @ts-ignore
      bootbox.confirm(basketLoadData.deleteMessage, (result: boolean) => {
        if (result) {
          const templateId = $(e.currentTarget).data("template-id");
          this.deleteTemplate(templateId);
        }
      });
    });
  }

  static initTemplateDetails() {
    $(".js-template--collapse-details").each((idx, el) => {
      el.addEventListener("show.bs.collapse", async () => {
        const $details = $(el).find(".js-template--details");
        await this.getTemplateDetails($details, $details.data("get-details"));
      });
    });
  }

  static initOrderProduct() {
    const isProductPage = 0;
    const isOnlyChange = 0;
    const isTemplate = 1;
    const isChangeOfOrderUnit = 0;
    const isAddToBasket = 1;

    const $templateDetails = $(".js-template--details");

    $templateDetails.off("submit", ".js-order-product");
    $templateDetails.on("submit", ".js-order-product", (e) => {
      const $form = $(e.target);

      ProductHelper.updateQuantityAndPrice(
        e,
        $form,
        isProductPage,
        isOnlyChange,
        isTemplate,
        isChangeOfOrderUnit,
        isAddToBasket
      );
    });
  }

  static async getTemplateDetails($target: JQuery<HTMLElement>, action: string) {
    const templateData = await axios.get(action);
    $target.html(templateData.data.viewTemplateDetail);

    $target.off("click", ".js-template--delete-line");
    $target.on("click", ".js-template--delete-line", (e) => {
      e.preventDefault();
      Templates.deleteTemplateLine($(e.currentTarget));
    })

    $target.find(".js-pagination a").off("click");
    $target.find(".js-pagination a").on("click", async (e) => {
      e.preventDefault();
      await Templates.getTemplateDetails($target, $(e.currentTarget).prop("href"));
    });
  }

  static addTemplate(templateId: number) {
    // @ts-ignore
    this.executeTemplateAction("add", templateId, basketLoadData.messageConfirmedAddToBasket, basketLoadData.messageFailedAddToBasket);
  }

  static deleteTemplate(templateId: number) {
    // @ts-ignore
    this.executeTemplateAction("delete", templateId, basketLoadData.messageConfirmedDeleteTemplate, basketLoadData.messageFailedDeleteTemplate);
  }

  static deleteTemplateLine($deleteLink: JQuery<HTMLElement>) {
    // @ts-ignore
    bootbox.confirm({
      // @ts-ignore
      message: basketLoadData.messageDeleteProduct,
      buttons: {
        confirm: {
          // @ts-ignore
          label: basketLoadData.messageDeleteProductOk
        },
        cancel: {
          // @ts-ignore
          label: basketLoadData.messageDeleteProductCancel
        }
      },
      callback: async (result: boolean) => {
        if (result) {
          const action = $deleteLink.attr("href") as string;
          const templateId = $deleteLink.data("template-id");
          const templateLineId = $deleteLink.data("template-line-id");
          const productNumber = $deleteLink.data("product-number");

          const postData = {
            templateId: templateId,
            templateLineId: templateLineId
          };

          await axios.post(action, postData);

          // @ts-ignore
          toastr["success"](String.format(basketLoadData.messageConfirmedDeleteProduct, productNumber));

          const $details = $deleteLink.closest(".js-template--details");
          const currentPageIndex = parseInt($(".js-pagination--current-page-index").val() as string);
          const actionUrl = withQuery($details.data("get-details"), { page: currentPageIndex + 1 });

          await this.getTemplateDetails($details, actionUrl);
        }
      }
    });
  }

  static executeTemplateAction(action: string, templateId: number, confirmationMessage: string, errorMessage: string) {
    // @ts-ignore
    const postUrl = basketLoadData.actionUrl;
    // @ts-ignore
    const pageUrl = basketLoadData.pageUrl;

    const postData = {
      action: action,
      templateId: templateId
    };

    axios
      .post(postUrl, postData, { timeout: 30000 })
      .then((response: AxiosResponse) => response.data)
      .then(() => {
        toastr.success(confirmationMessage);

        switch (action) {
          case "add":
            MiniBasket.load();
            break;
          case "delete":
            window.location.href = pageUrl;
            break;
          }
      })
      .catch((error: AxiosError) => {
        console.error(error);
        toastr.error(errorMessage);
      });
  }
}
