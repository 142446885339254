﻿import 'toastr';
import $ from "jquery";
import axios, { AxiosError, AxiosResponse } from "axios";
import ProductHelper from "../helpers/product-helper";
import MiniBasket from "../components/mini-basket";
import MessageDialog from "../components/bs5-message-dialog";
import NatchGtm, { EventNamesSchema, NatchOsDataLayerProduct } from "natch-gtm4";
import eventBus from "../utils/event-bus";
import { BasketTotalsUpdateModel } from "../models/basket-totals";

export default class BasketStep1 {
    private static totalNet: number;
    private static totalNetAvailable: number;

    static init() {
        // @ts-ignore
        if (typeof (basketLoadData) === "undefined") {
            return;
        }

        BasketStep1.initDropdown();
        BasketStep1.initSaveTemplate();
        BasketStep1.initChangeProduct();
        BasketStep1.initDeleteProduct();
        BasketStep1.initDeleteBasket();
        BasketStep1.initFormSubmit();

        // @ts-ignore
        if (basketLoadData.isTemplate === 0) {
            // @ts-ignore
            if (basketLoadData.validateStockData) {
                BasketStep1.checkStockData(); // when stock must be validated, the calculation of the price data will be executed in the callback
            } else {
                BasketStep1.calculatePriceData();
            }
        }
    }

    static initDropdown() {
        $(".js-dropdown-options li a").on("click", (e) => {
            e.preventDefault();

            const $option = $(e.currentTarget);

            $option
                .closest(".js-dropdown-container")
                .find(".js-dropdown-name")
                .text($option.data("name"));

            $option
                .closest(".js-dropdown-container")
                .parent()
                .find(".js-dropdown-value")
                .attr("href", $option.data("value"));
        });
    }

    static initSaveTemplate() {
        $("#save-template").on("click", (e) => {
            const action = (name: string, reference: string) => {
                const postData = {
                    name: name,
                    reference: reference
                };

                axios
                  .post("/api/basket/save-template", postData)
                  .then((response: AxiosResponse) => response.data)
                  .then(data => {
                      // @ts-ignore
                      toastr.success(basketLoadData.saveTemplateMsg);
                  })
                  .catch((error: AxiosError) => {
                      console.log(error);

                      if (error.response?.data) {
                          toastr.warning(error.response?.data);
                      } else {
                          // @ts-ignore
                          toastr.error(basketLoadData.saveTemplateErrorMsg);
                      }
                  });
            };

            BasketStep1.callSaveTemplateModal(action, "", "");
        });
    }

    static initChangeProduct() {
        const isProductPage = 0;
        const isOnlyChange = 0;
        const isAddToBasket = 0;

        // @ts-ignore
        const $container = basketLoadData.isTemplate === 1 ? $("#template-list") : $("#form-basket-step-1");

        $container.off("change", "[name=quantity]");
        $container.on("change", "[name=quantity]", (e) => {
            let $item = $(e.currentTarget).closest("tr");

            const isMobile = $(".js-product-grid").is(":visible");
            if (isMobile) {
                $item = $(e.currentTarget).closest(".js-product-grid-item");
            }

            ProductHelper.updateQuantityAndPrice(
                e,
                $item,
                isProductPage,
                isOnlyChange,
                // @ts-ignore
                basketLoadData.isTemplate,
                0,
                isAddToBasket
            );

            ProductHelper.updateDiscountDisplay($item);
        });

        $container.off("change", "[name=orderunitid]");
        $container.on("change", "[name=orderunitid]", (e) => {
            let $item = $(e.currentTarget).closest("tr");

            const eanCode = $(e.currentTarget).find("option:selected").data("ean");
            $item.find("span.js-product-ean").text(eanCode);
            $item.find("input.js-product-ean").val(eanCode);

            const isMobile = $(".js-product-grid").is(":visible");
            if (isMobile) {
                $item = $(e.currentTarget).closest(".js-product-grid-item");
            }

            ProductHelper.updateQuantityAndPrice(
                e,
                $item,
                isProductPage,
                isOnlyChange,
                // @ts-ignore
                basketLoadData.isTemplate,
                1,
                isAddToBasket
            );

            ProductHelper.updateDiscountDisplay($item);
        });

        $container.off("keydown", "[name=quantity], [name=orderunitid]");
        $container.on("keydown", "[name=quantity], [name=orderunitid]", (e) => {
            if (e.keyCode === 13) {
                e.currentTarget.blur();
            }
        });
    }

    static initDeleteProduct() {
        // @ts-ignore
        const $container = basketLoadData.isTemplate === 1 ? $("#template-list") : $("#form-basket-step-1");

        $container.off("click", ".js-delete-basketline");
        $container.on("click", ".js-delete-basketline", (e) => {
            e.preventDefault();

            const $deleteLink = $(e.currentTarget);

            // @ts-ignore
            bootbox.confirm({
                // @ts-ignore
                message: basketLoadData.messageDeleteProduct,
                buttons: {
                    confirm: {
                        // @ts-ignore
                        label: basketLoadData.messageDeleteProductOk
                    },
                    cancel: {
                        // @ts-ignore
                        label: basketLoadData.messageDeleteProductCancel
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        const action = $deleteLink.attr("href") as string;
                        const basketId = $deleteLink.data("basketid");
                        const basketLineId = $deleteLink.data("basketlineid");
                        const productNumber = $deleteLink.data("productno");

                        const jsonString = $deleteLink.parents("[data-datalayer-cartitem]").attr("data-datalayer-cartitem");
                        const productDataLayerFields = jsonString ? JSON.parse(jsonString) : null;

                        BasketStep1.postDeleteProduct(
                            action,
                            basketId,
                            basketLineId,
                            productNumber,
                            productDataLayerFields
                        );
                    }
                }
            });
        });
    }

    static initDeleteBasket() {
        const $form = $("#form-basket-step-1");

        $form.off("click", ".js-delete-basket");
        $form.on("click", ".js-delete-basket", (e) => {
            e.preventDefault();

            const $deleteLink = $(e.currentTarget);

            // @ts-ignore
            bootbox.confirm({
                // @ts-ignore
                message: basketLoadData.messageDeleteBasket,
                buttons: {
                    confirm: {
                        // @ts-ignore
                        label: basketLoadData.messageDeleteBasketOk
                    },
                    cancel: {
                        // @ts-ignore
                        label: basketLoadData.messageDeleteBasketCancel
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        const basketId = $deleteLink.data("basketid");
                        BasketStep1.postDeleteBasket(basketId);
                    }
                }
            });
        });
    }

    static initFormSubmit() {
        $("#form-basket-step-1").on("click", "[type=submit]", (e) => {
            e.preventDefault();

            const $form = $(e.currentTarget).closest("form");

            // @ts-ignore
            if (basketLoadData.validateEmptyStockData) {
                BasketStep1.checkEmptyStockData($form);
            } else {
                $form.trigger("submit");
            }
        });
    }

    static callSaveTemplateModal(action: any, name: string, reference: string) {
        const form = $("<form></form>");

        const divName = $("<div class='mb-3'></div>");
        // @ts-ignore
        divName.append("<label for='template-name' class='form-label'>" + basketLoadData.templateLabelName + "</label>");
        // @ts-ignore
        divName.append("<input type='text' class='form-control' autocomplete='off' placeholder='" + basketLoadData.templateDefaultTextName + "' value='" + name + "' id='template-name' />");
        form.append(divName);

        const divReference = $("<div class='mb-3'></div>");
        // @ts-ignore
        divReference.append("<label for='template-reference' class='form-label'>" + basketLoadData.templateLabelReference + "</label>");
        // @ts-ignore
        divReference.append("<input type='text' class='form-control' autocomplete='off' placeholder='" + basketLoadData.templateDefaultTextReference + "' value='" + reference + "' id='template-reference' />");
        form.append(divReference);

        // @ts-ignore
        bootbox.dialog({
            message: form,
            // @ts-ignore
            title: basketLoadData.templateBootboxTitle,
            buttons: {
                cancel: {
                    label: "Cancel"
                },
                ok: {
                    label: "OK",
                    className: "btn-primary",
                    callback: function() {
                        const name = form.find("#template-name").val();
                        const reference = form.find("#template-reference").val();

                        if (name === null && reference === null) {
                            console.log("Empty name and reference");
                        } else {
                            action(name, reference);
                            return;
                        }
                    }
                }
            }
        });
    }

    static postDeleteBasket(basketId: number) {
        const postData = {
            basketId: basketId
        };

        const q = $.ajax({
            type: "POST",
            url: "/api/basket/deletebasket",
            data: JSON.stringify(postData),
            contentType: "application/json; charset=utf-8"
        });

        q.done((data) => {
            // @ts-ignore
            toastr["success"](basketLoadData.messageConfirmedDeleteBasket);

            BasketStep1.calculatePriceData();
            MiniBasket.load();

            // make the redirect wait a bit otherwise can't see the toastr message
            setTimeout(function() {
                window.location.href = "/";
            }, 2000);
        });

        q.fail((xhr, textStatus, errorThrown) => {
            toastr["error"]("Could not delete basket.");
        });
    }

    static postDeleteProduct(action: string, basketId: number, basketLineId: number, productNumber: string, dataLayerProduct: NatchOsDataLayerProduct | null) {
        const searchParams = new URLSearchParams(window.location.search);

        const postData = {
            basketId: basketId,
            basketLineId: basketLineId,
        };

        if (searchParams.has("page")) {
            postData["page"] = searchParams.get("page") as unknown as number;
        }

        if (searchParams.has("up")) {
            postData["undeliverableProductList"] = searchParams.get("up");
        }

        const q = $.ajax({
            type: "POST",
            url: action,
            data: JSON.stringify(postData),
            contentType: "application/json; charset=utf-8"
        });

        q.done((data) => {
            // @ts-ignore
            toastr["success"](String.format(basketLoadData.messageConfirmedDeleteProduct, productNumber));

            // @ts-ignore
            if (basketLoadData.isTemplate === 1) {
                $("#template-details-" + basketId).html(data.viewTemplateDetail);
                BasketStep1.initDeleteProduct();

                return;
            }

            BasketStep1.calculatePriceData();
            MiniBasket.load();

            if (dataLayerProduct) {
                const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
                const netTotalLineAmount = (dataLayerProduct._price ?? 0) * (dataLayerProduct._quantity ?? 0);
                natchGtm.trackRemoveFromCart(dataLayerProduct, netTotalLineAmount);
            }

            if (data.returnUrl !== null) {
                window.location.href = data.returnUrl;

                return;
            }

            $("#checkout-basket").html(data.viewCheckoutBasket);
            BasketStep1.initDeleteProduct();
        });

        q.fail((xhr, textStatus, errorThrown) => {
            toastr["error"]("Could not delete product.");
        });
    }

    static calculatePriceData() {
        const postData = {
            // @ts-ignore
            basketId: basketLoadData.basketId,
        };

        const q = $.ajax({
            type: "POST",
            url: "/api/basket/pricedata",
            data: JSON.stringify(postData),
            contentType: "application/json; charset=utf-8"
        });

        q.done((data) => {
            // @ts-ignore
            $(".js-checkout-total").html(" " + Globalize.format(data.totalNet, "N2"));

            // @ts-ignore
            $(".js-checkout-total-available").html(" " + Globalize.format(data.totalNetAvailable, "N2"));

            BasketStep1.totalNet = data.totalNet;
            BasketStep1.totalNetAvailable = data.totalNetAvailable;

            // @ts-ignore
            if (basketLoadData.isTemplate === 0) {
                const basketTotalsUpdateModel: BasketTotalsUpdateModel = {
                    totalNet: data.totalNet,
                    totalNetAvailable: data.totalNetAvailable,
                    discount: data.discount,
                    transportCost: data.transportCost,
                    vatPercentage: data.vatPercentage
                };

                eventBus.emit("basket-totals-update", basketTotalsUpdateModel);
            }
        });

        q.fail((xhr, textStatus, errorThrown) => {
            console.error("Error in recalculation of total price and discount in basket.");
        });
    }

    static checkEmptyStockData($form) {
        // @ts-ignore
        if (NProgress) NProgress.start();

        // @ts-ignore
        const messageDialog = MessageDialog.createInstance("nt-message-dialog--check-empty-stock-data", basketLoadData.messageCheckStockData);
        messageDialog.show();

        MessageDialog.getElement("nt-message-dialog--check-empty-stock-data").addEventListener("shown.bs.modal", () => {
            const q = $.ajax({
                type: "GET",
                url: "/api/basket/checkemptystockdata"
            });

            q.done((data) => {
                if (data.isStockEmpty) {
                    let url = document.location.href;
                    url += (url.split("?")[1] ? "&" : "?") + "isStockEmpty=True";

                    document.location.href = url;
                } else {
                    if (typeof $form !== "undefined") {
                        $form.submit();
                    }
                }
            });

            q.fail((xhr, textStatus, errorThrown) => {
                console.error(xhr);

                if (typeof xhr.responseJSON !== "undefined") {
                    toastr.error(xhr.responseJSON.exceptionMessage);
                }
            });

            q.always(() => {
                // @ts-ignore
                if (NProgress) NProgress.done();

                messageDialog.hide();
            });
        })
    }

    static checkStockData() {
        // @ts-ignore
        if (NProgress) NProgress.start();

        // @ts-ignore
        const messageDialog = MessageDialog.createInstance("nt-message-dialog--check-stock-data", basketLoadData.messageCheckStockData)
        messageDialog.show();

        MessageDialog.getElement("nt-message-dialog--check-stock-data").addEventListener("shown.bs.modal", () => {
            axios.get(`/api/basket/checkstockdata?forceUpdate=true`, { timeout: 30000 })
              .then((response: AxiosResponse) => response.data)
              .then(data => {
                  if (data.isStockStatusChange) {
                      let url = document.location.href;
                      url += (url.split("?")[1] ? "&" : "?") + "isStockChange=True";

                      document.location.href = url;
                  } else {
                      for (let psi = 0; psi < data.productStockList.length; psi++) {
                          ProductHelper.updateStockIndicator(data.productStockList[psi]);
                      }

                      BasketStep1.calculatePriceData();
                  }
              })
              .catch((error: AxiosError) => {
                  console.error(error);

                  if (error.response?.statusText) {
                      toastr.error(error.response?.statusText);
                  }
              })
              .finally(() => {
                  // @ts-ignore
                  if (NProgress) NProgress.done();

                  messageDialog.hide();
              });
        })
    }
}
