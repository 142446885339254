﻿export default class DiscountValidator {
    static showDiscount(input): boolean {
        return input.discountPercentage !== 0 && input.discountUnitsPerPack !== 0;
    }

    static showDiscountValue(input): boolean {
        return (
            this.showDiscount(input) && (input.requestedUnitsPerPack * input.requestedQuantity) % input.discountUnitsPerPack === 0
        );
    }

    static calculateNetPrice(input): number {
        return this.showDiscountValue(input)
            ? input.grossPrice + input.grossPrice / 100 * input.discountPercentage
            : input.grossPrice;
    }
}