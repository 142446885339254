﻿interface String {
  format: any;
  slugify(): string;
  toArray: any;
  toToastrMessageType(): string;
}

String.prototype.format = function () {
  let args = arguments;
  return (this as string).replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

String.prototype.slugify = function () {
  let stringValue = this as string;

  stringValue = stringValue.replace(/^\s+|\s+$/g, "");

  // make the string lowercase
  stringValue = stringValue.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  const to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (let i = 0, l = from.length ; i < l ; i++) {
    stringValue = stringValue.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // remove invalid chars
  stringValue = stringValue.replace(/[^a-z0-9 -]/g, "")
    // collapse whitespace and replace by -
    .replace(/\s+/g, "-")
    // collapse dashes
    .replace(/-+/g, "-");

  return stringValue;
}

String.prototype.toArray = function () {
  return (this as string).replace(/\[|\]/g, "").split(",") as Array<string>;
};

String.prototype.toToastrMessageType = function () {
  const messageTypeList = {
    Success: "success",
    Error: "error",
    Information: "info",
    Warning: "warn",
  };

  return messageTypeList[this as string];
};
