﻿import mitt from "mitt"; // Import mitt

const emitter = mitt(); // Initialize mitt

type Event = "basket-totals-update" | "mobile-product-filter-activate" | "quick-scan-update" | "quick-scan-toggle-scanner";

class EventBus {
    constructor() {

    }

    public emit(event: Event, eventData?: any) {
        emitter.emit(event, eventData);
    }

    public on(eventName: Event, eventHandler: (data: any) => void) {
        emitter.on(eventName, eventHandler);
    }

    public off(eventName: Event, eventHandler: (data: any) => void) {
        emitter.off(eventName, eventHandler);
    }
}

// singleton it:
const eventBus = new EventBus();
export default eventBus;
