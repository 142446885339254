﻿import 'toastr';
import $ from "jquery";
import axios, { AxiosError, AxiosResponse } from "axios";
import NatchGtm, { EventNamesSchema, NatchOsDataLayerProduct } from "natch-gtm4";
import BasketStep1 from "../pages/basket-step-1";
import MiniBasket from "../components/mini-basket";
import ProductHelper from "./product-helper";
import Multilang from "../utils/multilang";
import type { AddToBasketRequest } from "../models/baskets";

export default class BasketHelper {
  static addToBasket(postData: AddToBasketRequest, dataLayerProduct: NatchOsDataLayerProduct | null) {
    Multilang.load();

    axios
      .post("/api/basket/add-from-product", postData)
      .then((response: AxiosResponse) => response.data)
      .then(data => {
        if (!data) {
            return;
        }

        toastr["success"](data);

        // removes focus effect on add button
        $(":focus").trigger("blur");

        MiniBasket.load();

        if (dataLayerProduct) {
            dataLayerProduct.list_id = postData.attributionListId;
            dataLayerProduct.list_name = postData.attributionListName;

            const natchGtm = new NatchGtm(EventNamesSchema.OfficialGA4);
            const netTotalLineAmount = (dataLayerProduct._price ?? 0) * (dataLayerProduct._quantity ?? 0);
            natchGtm.trackAddToCart(dataLayerProduct, netTotalLineAmount);
        }
      })
      .catch((error: AxiosError) => {
        console.error(error);

        if (error.response?.status === 403) {
            toastr["warning"](error.response?.statusText);
        }

        if (error.response?.status === 404 || error.response?.status === 500) {
            toastr["error"](Multilang.getTranslation("AddToBasket.message.exception", "Er trad een fout op!"));
        }
      });
  }

    static changeBasketLine(
      basketLineId: number,
      productId: number,
      quantity: number,
      unitPrice: number,
      orderUnitId: number,
      orderSource: string,
      isTemplate: number,
      templateId: number
    ) {
      const postData = {
          basketLineId: basketLineId,
          productId: productId,
          quantity: quantity,
          unitPrice: unitPrice,
          orderUnitId: orderUnitId,
          orderSource: orderSource,
          isTemplate: isTemplate,
          templateId: templateId
      };

      axios
        .post("/api/basket/change-line", postData)
        .then((response: AxiosResponse) => response.data)
        .then(data => {
          if (!data) {
              return;
          }

          toastr["success"](data.message);

          if (isTemplate === 0) {
              ProductHelper.updateStockIndicator(data.productStock);
              BasketStep1.calculatePriceData();
          }

          MiniBasket.load();
        })
        .catch((error: AxiosError) => {
          console.error(error);

          if (error.response?.status === 403) {
              toastr["warning"](error.response?.statusText);
          }

          if (error.response?.status === 404 || error.response?.status === 500) {
              toastr["error"]("Could not change basket line.");
          }
        });
    }
}
