import DataLayerHelper from "../helpers/data-layer-helper";
import Multilang from "../utils/multilang";

export default class SearchScan {
  static init() {
    const searchScanPage = document.querySelector("#search-scan-page") as HTMLElement | null;

    if (!searchScanPage) {
      return;
    }

    const barcodePickerElement = document.querySelector("#search-scan-barcode-picker") as HTMLElement | null;

    if (!barcodePickerElement) {
      return;
    }

    const scanditLicenseKey = barcodePickerElement.dataset.ntLicenseKey as string;
    const searchProductUrl = barcodePickerElement.dataset.ntSearchProductUrl as string;
    const errorMessagePermission = Multilang.getTranslation("scanner.message.error.permission", "U dient de browser toegang te geven tot de camera om de scanfunctie te gebruiken.");
    const errorMessageOther = Multilang.getTranslation("scanner.message.error.other", "Er is een fout opgetreden.");

    try {
      // https://docs.scandit.com/stable/web/
      // https://websdk.scandit.com/

      // @ts-ignore
      if (typeof ScanditSDK === "undefined") {
        return;
      }

      let searchArea = { x: 0.2, y: 0.45, width: 0.6, height: 0.1 };

      if (/CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent)) {
        // set search area to full for Chrome on iOS
        searchArea = { x: 0.0, y: 0.0, width: 1.0, height: 1.0 };
      }

      // @ts-ignore
      const scanSettings = new ScanditSDK.ScanSettings({
        enabledSymbologies: ["ean8", "ean13", "upca", "upce", "code128", "code39", "code93", "itf"],
        codeDuplicateFilter: 1000,
        searchArea: searchArea
      });

      // @ts-ignore
      ScanditSDK.configure(scanditLicenseKey, {
        engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk/build"
      }).then(() => {
        // @ts-ignore
        ScanditSDK.BarcodePicker.create(barcodePickerElement, {
          playSoundOnScan: true,
          vibrateOnScan: true
        }).then((barcodePicker) => {
          // barcodePicker is ready here to be used
          barcodePicker.applyScanSettings(scanSettings);
          barcodePicker.resumeScanning();

          barcodePicker.on("scan", (scanResult) => {
            barcodePicker.pauseScanning();

            const barcode = scanResult.barcodes.reduce((string, barcode) => {
              return string + barcode.data;
            }, "");

            window.location.href = `${searchProductUrl}?searchTerm=${encodeURI(barcode)}&source=scanner`;
          });

          DataLayerHelper.trackCustomEvent("search_scan_open_scanner")
        }).catch((error) => {
          if (String(error).includes("Permission") || String(error).includes("NotAllowedError")) {
            toastr.error(errorMessagePermission);
          }
          else {
            toastr.error(`${errorMessageOther} (${error})`);
          }

          console.error(error);
        });
      }).catch((error) => {
        toastr.error(`${errorMessageOther} (${error})`);
        console.error(error);
      });
    } catch (error) {
      toastr.error(`${errorMessageOther} (${error})`);
      console.error(error);
    }
  }
}
